
import { Component, Vue } from 'nuxt-property-decorator'
import { client, market } from '@/db/home/market'
@Component
export default class extends Vue {
  clientList: Array<any> = client
  marketList: Array<any> = market
  customerList: any[] = [
    {
      title: '合作高校（部分）',
      logos: [
        {
          logo: require("@/assets/images/college/beijing.svg"),
          name: "北京大学",
        },
        {
          logo: require("@/assets/images/college/jilin.svg"),
          name: "吉林大学",
        },
        {
          logo: require("@/assets/images/college/hagongda.svg"),
          name: "哈尔滨工业大学",
        },
        {
          logo: require("@/assets/images/college/shangjiaoda.svg"),
          name: "上海交通大学",
        },
        {
          logo: require("@/assets/images/college/dalianligong.svg"),
          name: "大连理工大学",
        },
        {
          logo: require("@/assets/images/college/nanjing.svg"),
          name: "南京大学",
        }
      ]
    },
    {
      title: '高端实验室（部分）',
      width: '1.27rem',
      logos: [
        {
          logo: require("@/assets/images/home/lab/zhijiang.svg"),
          name: "",
        },
        {
          logo: require("@/assets/images/home/lab/pengcheng.png"),
          name: "",
        },
        {
          logo: require("@/assets/images/home/lab/songshan.png"),
          name: "",
        },
        {
          logo: require("@/assets/images/home/lab/gusu.png"),
          name: "",
        },
      ]
    },
    {
      title: '省市产研院平台（部分）',
      width: '1.27rem',
      logos: [
        {
          logo: require("@/assets/images/home/province/shandong.png"),
          name: "",
        },
        {
          logo: require("@/assets/images/home/province/sse.png"),
          name: "",
        },
        {
          logo: require("@/assets/images/home/province/jiqt.png"),
          name: "",
        },
        {
          logo: require("@/assets/images/home/province/sicui.svg"),
          name: "",
        },
      ]
    },
    {
      title: '科研院所（部分）',
      width: '1.27rem',
      logos: [
        {
          logo: require("@/assets/images/home/research/kexueyuan.png"),
          name: "",
        },
        {
          logo: require("@/assets/images/home/research/nongye.png"),
          name: "",
        },
        {
          logo: require("@/assets/images/home/research/air.png"),
          name: "",
        },
        {
          logo: require("@/assets/images/home/research/buzhidao.png"),
          name: "",
        },
      ]
    },
    {
      title: '合作企业（部分）',
      width: '1.27rem',
      logos: [
        {
          logo: require("@/assets/images/home/enterprise/huadian.svg"),
          name: "",
        },
        {
          logo: require("@/assets/images/home/enterprise/dazhong.svg"),
          name: "",
        },
        {
          logo: require("@/assets/images/home/enterprise/hangtian.svg"),
          name: "",
        },
        {
          logo: require("@/assets/images/home/enterprise/pingguo.svg"),
          name: "",
        },
      ]
    }
  ]
  changeSwiper(i: number) {
    const refCarousel: any = this.$refs.refCarousel
    refCarousel.next()
  }
}
