export let client = [
  [
    {
      logoUrl: require("@/assets/images/college/beijing.svg"),
      name: "北京大学",
    },
    {
      logoUrl: require("@/assets/images/college/jilin.svg"),
      name: "吉林大学",
    },
    {
      logoUrl: require("@/assets/images/college/hagongda.svg"),
      name: "哈尔滨工业大学",
    },
    {
      logoUrl: require("@/assets/images/college/shangjiaoda.svg"),
      name: "上海交通大学",
    },
    {
      logoUrl: require("@/assets/images/college/dalianligong.svg"),
      name: "大连理工大学",
    },
    {
      logoUrl: require("@/assets/images/college/nanjing.svg"),
      name: "南京大学",
    },
  ],
  [
    {
      logoUrl: require("@/assets/images/college/shandongkeda.svg"),
      name: "山东科技大学",
    },
    {
      logoUrl: require("@/assets/images/college/fudan.svg"),
      name: "复旦大学",
    },
    {
      logoUrl: require("@/assets/images/college/nanjingligong.svg"),
      name: "南京理工大学",
    },
    {
      logoUrl: require("@/assets/images/college/hehai.svg"),
      name: "河海大学",
    },
    {
      logoUrl: require("@/assets/images/college/huadongligong.svg"),
      name: "华东理工大学",
    },
    {
      logoUrl: require("@/assets/images/college/shandongshifan.svg"),
      name: "山东师范大学",
    },
  ],
  [
    {
      logoUrl: require("@/assets/images/college/tianjin.svg"),
      name: "天津大学",
    },
    {
      logoUrl: require("@/assets/images/college/zhongkeyuan.svg"),
      name: "中国科学院",
    },
    {
      logoUrl: require("@/assets/images/college/nannongda.svg"),
      name: "南京农业大学",
    },
    {
      logoUrl: require("@/assets/images/college/nanjinggongcheng.svg"),
      name: "南京工程学院",
    },
    {
      logoUrl: require("@/assets/images/college/qingdaoligong.svg"),
      name: "青岛理工大学",
    },
    {
      logoUrl: require("@/assets/images/college/shandong.svg"),
      name: "山东大学",
    },
  ],
];
export let market = [
  {
    number: require("@/assets/images/home/market/server-num-1.svg"),
    iconUrl: require("@/assets/images/home/market/experiment.svg"),
    clientType: "国家/部委重点实验室",
  },
  {
    number: require("@/assets/images/home/market/server-num-2.svg"),
    iconUrl: require("@/assets/images/home/market/academic.svg"),
    clientType: "科研机构",
  },
  {
    number: require("@/assets/images/home/market/server-num-3.svg"),
    iconUrl: require("@/assets/images/home/market/user.svg"),
    clientType: "科研用户",
  },
  {
    number: require("@/assets/images/home/market/server-num-4.svg"),
    iconUrl: require("@/assets/images/home/market/enterprise.svg"),
    clientType: "企业客户",
  },
]
