
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
@Component
export default class extends Vue {
  @Prop({ default: 3 }) processNum!: number
  activeDot: number = 0
  activeProcess: number = 0
  isClick: boolean = false
  timer: any
  @Watch('activeDot') handleDotChange(val: number) {
    this.activeProcess = this.isClick
      ? val * 50
      : val == this.processNum - 1
      ? 0
      : (val + 1) * 50
  }
  mounted() {
    this.changeProcess(0, false)
  }
  destroyed() {
    clearInterval(this.timer)
  }
  changeProcess(i: number, status: boolean) {
    clearInterval(this.timer)
    this.isClick = status
    if (status) {
      if (i == 0) {
        this.activeProcess = 0
      }
      this.activeDot = i
      this.$emit('changeSwiper', i)
      setTimeout(() => {
        let n = this.activeDot == this.processNum - 1 ? 0 : this.activeDot + 1
        this.changeProcess(n, false)
      }, 500)
    } else {
      this.activeProcess =
        this.activeDot == this.processNum - 1 ? 0 : (this.activeDot + 1) * 50
      this.timer = setInterval(() => {
        this.activeDot =
          this.activeDot == this.processNum - 1 ? 0 : this.activeDot + 1
        this.$emit('changeSwiper', this.activeDot)
      }, 2000)
    }
  }
}
