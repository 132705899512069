import { render, staticRenderFns } from "./Market.vue?vue&type=template&id=0f581d94&scoped=true&"
import script from "./Market.vue?vue&type=script&lang=ts&"
export * from "./Market.vue?vue&type=script&lang=ts&"
import style0 from "./Market.vue?vue&type=style&index=0&id=0f581d94&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f581d94",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MumuTitle: require('/root/workspace/mumuxili-mobile-front_KVKI/components/Mumu/Title.vue').default,MumuProgress: require('/root/workspace/mumuxili-mobile-front_KVKI/components/Mumu/Progress.vue').default})
